import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { HomeLayout } from './PublicRoutes';
import Login from '../screens/login/Login';
import Home from '../screens/Home/Home';
import { ProtectedLayout, ProtectedRoute } from './ProtectedRoutes';
import FaqPage from '../screens/FaqPage/FaqPage';
import WhatCanIDoPage from '../screens/WhatCanIDoPage/WhatCanIDoPage';
import ContactusPage from '../screens/ContactusPage/ContactusPage';
import Statement from '../screens/InformationPage/Statement';
import Parliament from '../screens/InformationPage/Parliament';
import Referendum from '../screens/InformationPage/Referendum';
import BlogPage from '../screens/BlogPage/BlogPage';
import StoryTellingPage from '../screens/TruthTellingPage/TruthTellingPage';
import UploadStoryPage from '../screens/UploadStoryPage/UploadStoryPage';
import CulturePage from '../screens/InformationPage/Culture';
import HistoryPage from '../screens/InformationPage/History';
import SubscriptionPage from '../screens/SubscriptionPage/SubscriptionPage';

import StoryDetail from '../screens/Story/StoryDetail'
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<HomeLayout />}>
      <Route path={'/'} element={<Home />} />
      <Route path={'/faq'} element={<FaqPage />} />
      <Route path={'/whatcanido'} element={<WhatCanIDoPage />} />
      <Route path={'/contactus'} element={<ContactusPage />} />
      <Route path={'/statement'} element={<Statement />} />
      <Route path={'/parliament'} element={<Parliament />} />
      <Route path={'/referendum'} element={<Referendum />} />
      <Route path={'/blog'} element={<BlogPage />} />
      <Route path={'/truthTelling'} element={<StoryTellingPage />} />
      <Route path={'/uploadStory'} element={<UploadStoryPage />} />
      
      <Route path="/storydetail/:id" element={<StoryDetail/>}></Route>
      <Route path={'/Culture'} element={<CulturePage/>} />
      <Route path={'/History'} element={<HistoryPage />} />
      <Route path={'/subscription'} element={<SubscriptionPage />} />
        {/* <Route path="/login" element={<Login />} /> */}
      </Route>
      <Route element={<ProtectedLayout />}>
        {/* <Route path={'/'} element={<Home />} /> */}
      </Route>
    </>
  )
);
