import { Button, Drawer } from 'antd';
import React from 'react';
import { useState } from 'react';
import { RiMenu3Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../store/reducers/AuthSlice';

function MobMenu() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const logout = () => {
    localStorage.clear();
    dispatch(login({ isLogin: false, userData: [] }));
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#5c7754',
        }}
      >
        {/* <Button type="primary" onClick={showDrawer}>
          Open
        </Button> */}
        <div
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center'

          }}
        >
          <img onClick={() => { navigate('/') }} style={{ height: '80px' }} src={require('../../assets/logoImg.png')} />
        </div>
        <div onClick={showDrawer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
          <RiMenu3Line size={25} color='white' />
        </div>
      </div>

      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={open}
        style={{ backgroundColor: '#5c7754', color: 'white' }}
      >
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/') }}>
          Home
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/statement') }}>
          Statement from the Heart
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/parliament') }}>
          Voice to Parliament
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/referendum') }}>
          The Refrerendum
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/faq') }}>
          FAQ
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/contactus') }}>
          Contact Us
        </Button>
        { <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/whatcanido') }}>
          Blog
        </Button> }
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/truthTelling') }}>
          Truth Telling
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/Culture') }}>
          Culture
        </Button>
        <Button style={{width: '100%', marginTop: '10px', backgroundColor: '#060d29',color: 'white'}} onClick={() => { navigate('/History') }}>
          History
        </Button>
      </Drawer>
    </>
  );
}

export default MobMenu;
