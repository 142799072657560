import React from 'react';
import { Layout, theme, Carousel } from 'antd';
import Navbar from '../../components/Navbar';
import styles from "./Information.module.css";

const { Content, Footer } = Layout;



function Referendum() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout className="layout">
            <Navbar />
            <Content
                style={{
                    padding: '0 50px',
                    backgroundColor:'#060d29',
                    color: 'white',
                        display: 'grid',
                        gridTemplateRows: 'auto 1fr auto', 
                        minHeight: '100vh',
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        backgroundColor:'#060d29',
                        height: '100vh'
                    }}
                >
                    <div style={{ marginTop: '20px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', marginTop: '40px' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>The Referendum</h1>
                                {/* <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic' }}>What is the referendum?</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}>
                                {/* <h1 style={{ fontSize: '30px', textAlign: 'center' }}>ULURU STATEMENT FROM THE HEART</h1> */}
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Our Australian Constitution still does not recognise Aboriginal and Torres Strait Islander people. A “Yes” vote in the upcoming referendum will ensure this recognition.</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Voting is compulsory so everyone registered to vote in regular elections must weigh in on this change. What will you say?</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>In alignment with <a href='https://ulurustatement.org/the-statement/'>the Uluru Statement from the Hear</a> the referendum proposes that this symbolic recognition of our nation’s values comes with a practical mechanism for reconciliation: the Voice to Parliament</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>To find out more please see our pages on The Uluru Statement from the Heart and The Voice to Parliament. Having a referendum like this one is necessary to try to change the Constitution. Enshrining the Voice in the Constitution is a long-term commitment to listening to and consulting Indigenous Australians on matters that affect them.</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>To find out more about the referendum, and arguments for it, please take a look at the <a href='https://yes23.com.au/'>official Yes 23 website</a>.</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>If you have other questions on what exactly a successful referendum will mean, our Question and Answers page may be a good place to check out.</p>
                                

                            </div>
                        </div>

                        {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div className={styles.ColumnDiv}>
                                <div>
                                    <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic', fontSize: '20px' }}>Access the links below for ore information.</p>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                    <div style={{ padding: '10px 50px 10px 50px' }}>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                    </div>
                                </div>
                                <div>
                                    <div style={{border:'1px solid'}}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>
                                </div>

                            </div>

                        </div> */}
                    </div>
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
          color:'white'
                }}
            >
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default Referendum;
