import axios from "axios"
import {
    login
} from '../reducers/AuthSlice';
export const contactusMiddleware = (payload) => {


    return (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                let Data = {
                    // id: 0,
                    name: payload?.name,
                    email: payload?.email,
                    phonenumber: payload?.number,
                    message: payload?.message,
                    // creationDate: "2023-04-18T16:02:05.874Z"
                };
                

                const response = await fetch("https://api.greenslopesreconciliationactiongroup.com/api/Customer", {
                    method: 'POST',
                    headers: myHeaders,
                    // body: Data,
                    body: JSON.stringify(Data),
                    redirect: 'follow'
                });

                const result = await response.json();
                console.log("Success:", result);

                if (result.Status == 'Success') {
                    alert('Submitted')
                    window.location.reload(false);
                }
                
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
}
