import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
import axios from 'axios'; // Import axios for making HTTP requests
import Navbar from '../../components/Navbar';

const { Content, Footer } = Layout;

function StoryDetail() {
  const { id } = useParams(); // Extract story ID from the URL
  const [story, setStory] = useState(null); // State to store story details

  useEffect(() => {
    // Fetch story details based on story ID
    const fetchStoryDetails = async () => {
      try {
        const res = await axios.get(`https://api.greenslopesreconciliationactiongroup.com/api/Story/byid/${id}`); // Make API request
        setStory(res.data); // Set story state with fetched data
      } catch (err) {
        console.log(err); // Log any errors
      }
    };

    fetchStoryDetails(); // Call the function to fetch story details
  }, [id]); // Dependency array to re-run the effect when `id` changes

  // Display loading message if story data is not yet available
  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <Layout className="layout">
      <Navbar /> {/* Render Navbar component */}
      <Content style={{ padding: '50px 50px', backgroundColor: '#060d29', color: 'white', height: '100vw' }}>
        <div className='container text-center pt-5'>
          <h1 style={{ textTransform: "capitalize", color: "white", textAlign: "center" }}>{story.Name}</h1> {/* Display story name */}
          <p style={{ textTransform: "capitalize", color: "white", fontSize: "20px", textAlign: "center" }}>{story.Description}</p> {/* Display story description */}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', backgroundColor: '#5c7754', color: 'white' }}>
        Greenslopes Reconciliation Action Group {/* Footer text */}
      </Footer>
    </Layout>
  );
}

export default StoryDetail;
