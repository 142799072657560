import React from 'react';
import { Layout } from 'antd';
import Navbar from '../../components/Navbar';

const { Content, Footer } = Layout;

function HistoryPage() {
    return (
        <Layout className="layout">
            <Navbar />
            <Content style={{
                padding: '0 50px',
                backgroundColor: '#060d29',
                color: 'white',
                display: 'grid',
                gridTemplateRows: 'auto 1fr auto',
                minHeight: '100vh',
            }}>
                <div className="site-layout-content" style={{
                    backgroundColor: '#060d29',
                    height: '100vh'
                }}>
                    <div style={{ marginTop: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', marginTop: '40px' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Indigenous Historical Perspective</h1>
                                <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic' }}>Overview of Indigenous History</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>This section could cover historical events, timelines, and significant contributions of indigenous peoples to society.</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                <p style={{ marginTop: '30px', lineHeight: 1 }}>Further insights into historical challenges and triumphs.</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Detailed discussions on treaties, land rights, and other legal matters that have shaped the lives of indigenous people.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <Footer style={{
                textAlign: 'center',
                backgroundColor: '#5c7754',
                color: 'white'
            }}>
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default HistoryPage;
