import React, { useState } from 'react';
import { Layout, Button, Input, Spin, Form, Checkbox, Select } from 'antd';
import Navbar from '../../components/Navbar';
import '../../../src/components/styles/upload-story.css'
import ReCAPTCHA from "react-google-recaptcha";

import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';


const { Content, Footer } = Layout;


function UploadStoryPage() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);


    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select defaultValue="+61" style={{ width: 70, backgroundColor: "white" }}>
                <Option value="61" style={{ backgroundColor: "white" }}>+61</Option>
            </Select>
        </Form.Item>
    );

    const onFinish = async (values) => {
        console.log('Form values:', values);
        if (!captchaValue) {
            message.error('Please complete the captcha');
            return;
        }
        try {
            setSpinning(true);
            // Check if files are present in the fileList array
            if (fileList.length > 0) {
                // Send files to /api/GoogleDrive to fetch file IDs
                const formData = new FormData();
                fileList.forEach(file => {
                    formData.append('files', file.originFileObj);
                });
            
                const googleDriveResponse = await fetch('https://api.greenslopesreconciliationactiongroup.com/api/GoogleDrive', {
                    method: 'POST',
                    body: formData
                });
            
                if (googleDriveResponse.ok) {
                    const responseText = await googleDriveResponse.text();
                    try {
                        const responseData = JSON.parse(responseText);
                        const { files: uploadedFiles } = responseData;

                        const GoogleDriveFileIDs = uploadedFiles.map(file => ({
                            fileId: file.fileId,
                            fileName: file.fileName
                        }));
                        
                        // Construct values object with GoogleDriveFileIDs
                        // Construct values object without including files
                        const valuesWithoutFiles = { ...values, GoogleDriveFileIDs: GoogleDriveFileIDs};
                        delete valuesWithoutFiles.files;
    
                        // Call Storytelling API with files included
                        const storyResponse = await fetch('https://api.greenslopesreconciliationactiongroup.com/api/StoryTelling', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(valuesWithoutFiles)
                        });
            
                        if (storyResponse.ok) {
                            setFileList([]);
                            form.resetFields();
                            message.success('Story uploaded successfully!');
                        } else {
                            message.error('Failed to upload story. Please try again later.');
                        }
                    } catch (error) {
                        console.error('Failed to parse JSON response:', error);
                        message.error('Failed to upload story. Please try again later.');
                    }
                } else {
                    const responseBody = await googleDriveResponse.json();
                    message.error(responseBody.message || 'Error occurred while uploading the story, Check the count Of files');
                }
            } else {
                // If no files are present, call Storytelling API without files
                const storyResponse = await fetch('https://api.greenslopesreconciliationactiongroup.com/api/StoryTelling', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });
    
                if (storyResponse.ok) {
                    setFileList([]);
                    form.resetFields();
                    message.success('Story uploaded successfully!');
                } else {
                    message.error('Failed to upload story. Please try again later.');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to upload story. Please try again later.');
        } finally {
            setSpinning(false); // Stop spinning when form submission is complete or fails
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setCaptchaValue(value);
    };

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };



    return (
        <Layout className="layout">
            <Navbar page={'StoryTellingPage'} />
            <Content
                style={{
                    padding: '80px 50px',
                    backgroundColor: '#060d29',
                    color: 'white',
                    minHeight: '100vh',
                }}
            >
                <Form
                    name="basic"
                    form={form}
                    labelCol={{
                        span: 9,
                    }}
                    wrapperCol={{
                        span: 9,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label={<label style={{ color: "white" }}>First Name:</label>}
                        name="Firstname"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Firstname!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<label style={{ color: "white" }}>Last Name:</label>}
                        name="Lastname"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Lastname!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<label style={{ color: "white" }}>Preferred Name:</label>}
                        name="PreferredName"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<label style={{ color: "white" }}>Email:</label>}
                        name="Email"
                        extra={<div style={{ color: "rgba(255, 255, 255, 0.5)", fontStyle: "italic" }}>for internal use only</div>}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<label style={{ color: "white" }}>Mobile Number: </label>}
                        name="MobileNumber"
                        extra={<div style={{ color: "rgba(255, 255, 255, 0.5)", fontStyle: "italic" }}>for internal use only</div>}
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        name="Indigenous"
                        label={<label style={{ color: "white" }}>Are you Indigenous?:</label>}
                        rules={[{ required: true, message: 'Please Select' }]}
                    >
                        <Select placeholder="select">
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="Country"
                        label={<label style={{ color: "white" }}>Country/Location:</label>}
                        rules={[{ required: true, message: 'Please input tour country' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="FirstNationIdentity"
                        label={<label style={{ color: "white" }}>First nations identity:</label>}
                        rules={[{ required: true, message: 'Please input tour country' }]}
                    >
                        <Input />
                    </Form.Item>                    
                    <Form.Item
                        name="files"
                        label={<label style={{ color: "white" }}></label>}
                        // extra={<div style={{ color: "rgba(255, 255, 255, 0.5)", fontStyle: "italic" }}>Only Four Files are allowed to upload at a time</div>}
                    >
                        <Form.Item>
                            <Upload
                                beforeUpload={() => false}
                                accept='.mp4, image/png, image/jpeg, .mp3, .pdf'
                                multiple={true}
                                onChange={handleFileChange}
                            >
                                <Button icon={<UploadOutlined />}>Upload Media</Button>
                            </Upload>
                        </Form.Item>
                    </Form.Item>
                    
                    <Form.Item
                        name="Message"
                        label={<label style={{ color: "white" }}>Message:</label>}
                        rules={[{ required: true, message: 'Please Write your message about the story' }]}
                    >
                        <Input.TextArea rows={4} showCount maxLength={500} />
                    </Form.Item>
                    <Form.Item
                        name="captcha"
                        label={<label style={{ color: "white" }}>Captcha:</label>}
                        rules={[
                            {
                                required: true,
                                message: 'Please complete the captcha!',
                            },
                        ]}
                    >
                        <ReCAPTCHA
                            sitekey="6LcPb-kpAAAAAOWoqjXWbASmQX-Wden0Z2xjflw3"
                            onChange={onCaptchaChange}
                        />
                    </Form.Item>
                    <Form.Item label={<label style={{ color: "white" }}></label>}>
                        <Form.Item
                            name="Agreement"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                },
                            ]}
                        >
                            <Checkbox style={{ color: "white" }}>
                                I hereby confirm that all the information shared above is authentic and verifyable
                            </Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item style={{marginTop: "-20px"}} label={<label style={{ color: "white" }}></label>}>
                        <Form.Item
                            name="Acknowledge"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Please Accept')),
                                },
                            ]}
                        >
                            <Checkbox style={{ color: "white" }}>
                                I hereby acknowledge that all the media shared above is publically accessible and I am comfortable with it's accessibility.
                            </Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 9,
                            span: 9,
                        }}
                    >
                        {spinning ? (
                            <>
            <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 40,
                  color: "rgb(92, 119, 84)",
                  marginLeft: "30px"
                }}
                spin
              />
            }
          />
          <p style={{color: "rgb(92, 119, 84)"}}> Uploading your files... </p> </>
        ) : (
            <Button
                style={{ background: "rgb(92, 119, 84)" }}
                size="large"
                shape="round"
                type="primary"
                htmlType="submit"
            >
                Submit
            </Button>
        )}
                    </Form.Item>
                </Form>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
                    color: 'white'
                }}
            >
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default UploadStoryPage;
