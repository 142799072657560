import React from 'react';
import { Layout, theme, Carousel } from 'antd';
import Navbar from '../../components/Navbar';
import styles from "./WhatCanIDoPage.module.css";

const { Content, Footer } = Layout;



function WhatCanIDoPage() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout className="layout">
            <Navbar />
            <Content
                style={{
                    padding: '0 50px',
                    backgroundColor:'#060d29',
                    color:'white'
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        backgroundColor:'#060d29',
                        // height: '100vh'
                    }}
                >
                    <div style={{ marginTop: '20px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', marginTop: '40px' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>What Can I Do?</h1>
                                <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic' }}>Interested in helping our cause?</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div className={styles.ColumnDiv}>
                                <div>
                                    <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic', fontSize: '20px' }}>Access the links below for ore information.</p>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                    <div style={{ padding: '10px 50px 10px 50px' }}>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                    </div>
                                </div>
                                <div>
                                    <div style={{border:'1px solid'}}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
          color:'white'
                }}
            >
                 Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default WhatCanIDoPage;
