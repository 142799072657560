import React from 'react';
import { Layout, theme, Carousel } from 'antd';
import Navbar from '../../components/Navbar';
import styles from "./Information.module.css";

const { Content, Footer } = Layout;



function Parliament() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout className="layout">
            <Navbar />
            <Content
                style={{
                    padding: '0 50px',
                    // backgroundColor:'#fff'
                    backgroundColor:'#060d29',
                    color: 'white',
                        display: 'grid',
                        gridTemplateRows: 'auto 1fr auto', 
                        minHeight: '100vh',
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        backgroundColor:'#060d29',
                         height: '100vh'
                    }}
                >
                    <div style={{ marginTop: '20px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', marginTop: '40px' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>First Nations Voice to Parliament</h1>
                                <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic' }}>What is voice to parliament?</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>The Voice to Parliament will give independent advice to the Australian Government on policies directly affecting Aboriginal and Torres Strait Islander people.</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                <p style={{ marginTop: '30px', lineHeight: 1 }}>According the Yes23 page, </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>“Aboriginal and Torres Strait Islander people 
                                across the country are coming together to ask for a simple and practical form of recognition - 
                                a Voice in the laws and policies that directly affect them. This means giving them a direct line
                                 of communication with politicians in Canberra, allowing them to offer practical solutions to the 
                                 unique challenges they face in areas such as jobs, health, education, and justice. 
                                 By cutting out bureaucratic red tape and party politics, we can ensure that real community
                                  voices are heard and that resources are used effectively to achieve real results. 
                                  This is a simple and powerful step that we can take as a nation to recognise and 
                                  honour the contributions of Aboriginal and Torres Strait Islander people.” <a href='https://yes23.com.au/faqs-about-the-referendum'>(Yes 23 - FAQs)</a> </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>The principles of the Voice can be found in this document. Some key priorities include independence, representativeness, and accountability to communities. 
                                To find out more about the proposed Voice please browse at the Australian Government’s official 
                                <a href='https://yes23.com.au/faqs-about-the-referendum'>Aboriginal and Torres Strait Islander Voice website</a>.</p>
                            </div>
                        </div>

                        {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div className={styles.ColumnDiv}>
                                <div>
                                    <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic', fontSize: '20px' }}>Access the links below for ore information.</p>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                    <div style={{ padding: '10px 50px 10px 50px' }}>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                    </div>
                                </div>
                                <div>
                                    <div style={{border:'1px solid'}}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>
                                </div>

                            </div>

                        </div> */}
                    </div>
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
          color:'white'
                }}
            >
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default Parliament;
