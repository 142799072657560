import React, { useEffect, useState } from 'react';
import { Layout, Spin, Card } from 'antd';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const { Content, Footer } = Layout;

function BlogPage() {
    const [blogsData, setBlogsData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = async () => {
        setLoading(true);
        //const response = await fetch("https://localhost:44377/api/Blog"); 
        const response = await fetch("https://api.greenslopesreconciliationactiongroup.com/api/Blog"); 
        setLoading(false);
        const result = await response.json();
        if (result?.Status === 'Success') {
            setBlogsData(result?.Result);
            setLoading(false);
        }
    };
    const convertUrlsToLinks = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };
    
    const formatDescription = (description) => {
        const withBreaks = description.replace(/\n/g, '<br />');
        return convertUrlsToLinks(withBreaks);
    };
    

    return (
        <Layout className="layout">
            <Navbar page={'BlogPage'} />
            <Spin spinning={loading}>
                <Content
                    style={{
                        padding: '80px 50px',
                        backgroundColor: '#060d29',
                        color: 'white',
                        minHeight: '100vh',
                        overflowY: 'auto'  // Allow vertical scrolling
                    }}
                >
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Blog</h1>
                        {blogsData.map(blog => (
                        <Card 
                        style={{ width: '80%', 
                        marginTop: '20px', 
                        backgroundColor: '#5c7754',
                        marginBottom: '30px', 
                        maxHeight: '90vh', 
                        overflowY: 'auto' }}
                        >
                            {blog.videoPath && (
                            <video controls style={{
                                maxWidth: '100%',    
                                maxHeight: '80vh'
                            }}>
                            <source src={`https://api.greenslopesreconciliationactiongroup.com/${blog.videoPath}`} type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                            )}
                            {!blog.videoPath && blog.imagePath && (
                            <img alt="Blog Image" src={`https://api.greenslopesreconciliationactiongroup.com/${blog.imagePath}`} 
                            style={{
                                maxWidth: '100%',    
                                maxHeight: '80vh'    
                            }}
                            />
                            )}
                        <Card.Meta
                            title={blog.heading}
                            description={<>
                            <p>{moment(blog.date).format('DD-MM-YYYY')}</p>
                            <p dangerouslySetInnerHTML={{ __html: formatDescription(blog.description) }}></p>
                        </>}
                        />
                    </Card>
                ))}


                    </div>
                </Content>
            </Spin>
            <Footer style={{ textAlign: 'center', backgroundColor: '#5c7754', color: 'white' }}>
               Greenslopes Reconciliation Action Group
            </Footer>
        </Layout>
    );
}

export default BlogPage;


