import React from 'react';
import { Button, Checkbox, Form, Input, } from 'antd';
import { Layout, theme, Carousel } from 'antd';
import Navbar from '../../components/Navbar';
import styles from "./ContactusPage.module.css";
import { useDispatch } from 'react-redux';
import { contactusMiddleware } from '../../store/middleware/contactusMiddleware';
import TextArea from 'antd/es/input/TextArea';
import ReCAPTCHA from "react-google-recaptcha";

const { Content, Footer } = Layout;



function ContactusPage() {
    const dispatch = useDispatch();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const onFinish = (values) => {
        dispatch(contactusMiddleware(values));
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Layout className="layout">
            <Navbar />
            <Content
                style={{
                    padding: '0 50px',
                    backgroundColor:'#060d29',
                    color:'white',
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr auto', 
                    minHeight: '100vh',
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        backgroundColor:'#060d29',
                        height: '100vh'
                    }}
                >
                    <div style={{ marginTop: '20px', paddingBottom: '50px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            {/* <div className={styles.ColumnDiv} > */}
                            <div className={styles.containerDiv} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Contact Us:</h1>
                                {/* <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}

                                <div style={{ width: '100%', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', padding: 50, borderRadius: 20,backgroundColor:'white' }}>
                                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <p style={{ fontWeight: 'bold' }}>Email: </p>
                                            <p style={{ marginLeft: 5 }}> example@gmail.com</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <p style={{ fontWeight: 'bold' }}>Phone: </p>
                                            <p style={{ marginLeft: 5 }}> 123456789</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <p style={{ fontWeight: 'bold' }}>Social Media: </p>
                                            <div>
                                                <p style={{ marginLeft: 5 }}> Facebook</p>
                                                <p style={{ marginLeft: 5 }}> Twitter</p>
                                                <p style={{ marginLeft: 5 }}> Instagram</p>
                                            </div>
                                        </div> */}
                                    <Form
                                        // name="basic"
                                        // labelCol={{
                                        //     span: 8,
                                        // }}
                                        // wrapperCol={{
                                        //     span: 16,
                                        // }}
                                        // style={{
                                        //     maxWidth: 600,
                                        // }}
                                        // initialValues={{
                                        //     remember: true,
                                        // }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Phone Number"
                                            name="number"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input your Phone number!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item
                                            label="Message"
                                            name="message"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Message!',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>





                                        <Form.Item
                                        // wrapperCol={{
                                        //     offset: 8,
                                        //     span: 16,
                                        // }}
                                        >
                                            <div className={styles.buttonDiv} >
                                                <Button type="primary" htmlType="submit" style={{backgroundColor:'#44aa4a'}}>
                                                    Submit
                                                </Button>
                                                <div className={styles.captchaDiv}>
                                                    <ReCAPTCHA
                                                        sitekey="6Ldkx30mAAAAAL21_5xe3Cbbbr8Wz5Y2LEA7oajE"
                                                        onChange={(value) => console.log('value', value)}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </Form>

                                </div>
                            </div>
                            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                    <div style={{ border: '1px solid' }}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>

                                    <div style={{ border: '1px solid', marginTop: '50px' }}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>
                                </div> */}
                            {/* </div> */}
                        </div>

                        {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%', marginTop: '40px', }}>
                                <div>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Email : example@gmail.com</p>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Phone: 123456789</p>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Social Media: Facebook</p>
                                </div>
                                <div>
                                    <div style={{ border: '1px solid' }}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>
                                </div>

                            </div>

                        </div> */}
                    </div>
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
          color:'white'
                }}
            >
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default ContactusPage;
