import { Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { FileOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function WebMenu() {
  const navigate = useNavigate();

  const items = [
    {
      label: (<p onClick={()=>{navigate('/')}}>Home</p>),
      key: 'home',
    },
    {
      label: 'Information',
      key: 'Information',
      children: [
        {
          label: (<p onClick={()=>{navigate('/statement')}}>Statement from the Heart</p>),
          key: 'Statement from the Heart',
        },
        {
          label: (<p onClick={()=>{navigate('/parliament')}}>Voice to Parliament</p>),
          key: 'Voice to Parliament',
        },
        {
          label: (<p onClick={()=>{navigate('/referendum')}}>The Referendum</p>),
          key: 'The Referendum',
        },
        {
          label: (<p onClick={()=>{navigate('/Culture')}}>Indigenous Culture</p>),
          key: 'culture',
        },
        {
          label: (<p onClick={()=>{navigate('/History')}}>Indigenous History</p>),
          key: 'history',
        },
      ],
    },
    {
      label: (<p onClick={()=>{navigate('/faq')}}>FAQ</p>),
      key: 'FAQ',
    },
    // {
    //   label: (<p onClick={()=>{navigate('/whatcanido')}}>What Can I Do?</p>),
    //   key: 'What Can I Do?',
    // },
    {
      label: 'Contact Us',
      key: 'contact',
      children: [
        {
          
          label: (<p onClick={() => {navigate('/subscription')}}>Newsletter</p>),
          key: 'subscription',
        },
        {
          label: (<p onClick={()=>{navigate('/contactus')}}>Contact Us</p>),
          key: 'contact',
        }
      ]
    },
    {
      label: (<p onClick={()=>{navigate('/blog')}}>Blog</p>),
      key: 'Blog',
    },
    {
      label: (<p onClick={()=>{navigate('/truthTelling')}}>Truth Telling</p>),
      key: 'Truth Telling',
    },
    
    
  ];

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }

  const MenuItems = [
    getItem('Home', '1',),
    getItem('Information', 'sub1', [
      getItem('Statement from the Heart', '3'),
      getItem('Voice to Parliament', '4'),
      getItem('The Referendum', '5'),
    ]),
    getItem('FAQ', '2'),
    getItem('What Can I Do?', '6'),
    getItem('Contact Us', '7'),
  ];
  return (
    <Header
      style={{
        // position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        height:'80px',
        backgroundColor: '#5c7754',
      }}
    >
      <div
        style={{
          float: 'left',
          width: '60px',
          height: '60px',
        }}
      >
        <img onClick={() => { navigate('/') }} style={{height:'80px',cursor:'pointer'}}  src={require('../../assets/logoImg.png')} />
      </div>
      <div style={{ float: 'right', width: '40%' }}>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={['1']}
          items={items}
          style={{border:0,backgroundColor:'#5c7754',color:'white'}}
        />
      </div>
    </Header>
  );
}

export default WebMenu;
