import React, { useState } from 'react';
import { Button, Form, Input, Layout, message } from 'antd';
import axios from 'axios';  // Import axios
import Navbar from '../../components/Navbar';
import styles from "./SubscriptionPage.module.css"; 

const { Content, Footer } = Layout;

function SubscriptionPage() {
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const onFinish = async (values) => {
        console.log('Success:', values);
        try {
            const response = await axios.post('https://api.greenslopesreconciliationactiongroup.com/api/subscribe', values);
            setConfirmationMessage('Subscription successful!');
            form.resetFields();
        } catch (error) {
            console.error('Error subscribing:', error);
            message.error('Subscription failed, please try again.');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm();

    return (
        <Layout className="layout">
            <Navbar />
            <Content style={{ padding: '0 50px', backgroundColor:'#060d29', color:'white' }}>
                <div className="site-layout-content" style={{ backgroundColor:'#060d29' }}>
                    <div style={{ marginTop: '20px', paddingBottom: '50px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div className={styles.containerDiv} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center', color: 'white' }}>Subscribe to Our Newsletter:</h1>
                                <div style={{ width: '100%', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', padding: 50, borderRadius: 20, backgroundColor:'white' }}>
                                    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                                        <p style={{ color: '#000', marginBottom: '10px' }}>Sign up with your email address to receive news and updates.</p>
                                        <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[{ required: true, message: 'Please input your name' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[{ required: true, message: 'Please input your email', type: 'email' }]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.Item>
                                            <div className={styles.buttonDiv}>
                                                <Button type="primary" htmlType="submit" style={{backgroundColor:'#44aa4a'}}>
                                                    Subscribe
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                    {confirmationMessage && (
                                        <div style={{ marginTop: '20px', color: 'green', textAlign: 'center' }}>
                                            {confirmationMessage}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: '#5c7754', color:'white' }}>
                Greenslopes Reconciliation Action Group
            </Footer>
        </Layout>
    );
}

export default SubscriptionPage;


