import React, { useEffect, useState } from 'react';
import { Layout, theme, Collapse, Divider, Button, Modal, Input, Spin } from 'antd';
import Navbar from '../../components/Navbar';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { faqMiddleware } from '../../store/middleware/faqMiddleware';
import { useDispatch } from 'react-redux';

const { Panel } = Collapse;
const { Content, Footer } = Layout;
const { TextArea } = Input;


function FaqPage() {
    const dispatch = useDispatch();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const onSubmit = () => {
        setLoading(true)
        if (name && email && question) {
            let data = {
                name: name,
                email: email,
                phone: phone,
                question: question
            }
            dispatch(faqMiddleware(data))
                .then((data) => setLoading(false))
                .catch((data) => setLoading(false));
            setIsModalOpen(false);
        } else {
            alert('Please fill all fields first.')
        }

    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const [question, setquestion] = useState();
    const [name, setname] = useState();
    const [email, setemail] = useState();
    const [phone, setphone] = useState();
    const [loading, setLoading] = useState(false);
    const [faqData, setfaqData] = useState([]);

    console.log('faqData', faqData)

    useEffect(() => {
        getFAQ()
    }, []);

    const getFAQ = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", ".AspNetCore.Identity.Application=CfDJ8KQ9_KBcFi9Cu3eDViVJ7FvgRtprux03WlK27tizKa63y4q2pcTtilFCDbEATh8124uU1uVtn-evKsfPXt0-KMNS8w-d02hCQS5X5J-8mqu9K0jVxvfarRJU78q_lvxTE3OtPwsp4GC-QB5XGfiqH6sE0VWXAUIC-kZFPDSKy2zcrX4Cb2IKpLi5q-vRbE5z_6DVQVd2KdGYDdxzrWeNJhRRj0zuwPj_5IypCqk4l9DuUDi_pItdNJEumaNi-rSvWXAmJ74bDaZITYBZky0xaMwzBMJAGoaDdd9zvpX2OrbZm1Y5a0I-ou1Wi_mBXNdxIaO76BYF0Fc13haOvmN9EB4kCk4I6jEEQ6sSUfqLkyE-5H2RHEsOdbaldv7kZGZofz3Uj9mMGTAv2yoYN_9Lk-hUxueTXXJuWCSIpGzZcncAqHIq3CGg2YVdm2AXOZr3HZ9jXdfbjHo4AXGms128pGQdjVkj0whJXRHYl-ubN3aI3zS03Njfuj3DnOI9skhR0lVqiitOopkjFyM9ZlEdgnSgWtcA7s_dZM8tmP8Rt1kj78upFfoVBhNC6KJcuQNxTtni3Y6EtDA590uE0odhvKWGC7FDmlXP6CZQD6CGQIQ0rXJnDw6Ku2jXANhp4ZaFt06pMSf5v-IYu-ZS-kB9jVuFIEZr-BnE5UioOCiYRKtyMTXLHOpxH93AXDv1w5FVrSbvwwXim_JP7Q2_aYAopOY60PcSb4gfJODFmWFV6vnB");

        // const response = await fetch("https://api.greenslopesreconciliationactiongroup.com/api/FAQ", {
        const response = await fetch("https://api.greenslopesreconciliationactiongroup.com/api/FAQ", {

            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        });
        setLoading(false)
        const result = await response.json();
        console.log('reponse', result)
        if (result?.Status == 'Success') {
            setfaqData(result?.Result)
            setLoading(false)
        }

    }

    return (
        <Layout className="layout">
            <Navbar page={'Faq'} />
            <Spin spinning={loading}>
                <Content
                    style={{
                        padding: '0 50px',
                        backgroundColor: '#060d29',
                        color: 'white',
                        display: 'grid',
                        gridTemplateRows: 'auto 1fr auto', 
                        minHeight: '100vh',
                    }}
                >
                    <div
                        className="site-layout-content"
                        style={{
                            backgroundColor: '#060d29',
                            // height: '100vh'
                            padding: '10px 0 10px 0',

                        }}
                    >
                        <div style={{ marginTop: '20px', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '40px', }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}>
                                    <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Frequently Asked Questions (FAQ)</h1>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '70%', }}>

                                        {faqData?.map((item) => {
                                            return (
                                                <>
                                                    <Divider orientation="left"></Divider>
                                                    <Collapse style={{ backgroundColor: 'white' }}>
                                                        <Panel header={item?.question} key="1">
                                                            <p>{item?.answer}</p>
                                                        </Panel>
                                                    </Collapse>
                                                </>
                                            )
                                        })}

                                        {/* <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Will the Voice to Parliament amount to a third chamber of Parliament and therefore impact parliamentary sovereignty, a fundamental element of our constitutional system of government?" key="1">
                                                <p>What is proposed is a Voice to Parliament, not a Voice in Parliament. It will have no role in passing legislation; that will continue to be left to our elected representatives in the House of Representatives and the Senate, as currently prescribed by the Constitution. The proposed Constitutional amendment states that the Voice “may make representations” to Parliament. It will be up to Parliament to decide what it does with those representations. Indeed, the proposed Voice to Parliament is a very conservative change to our Constitution. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Will the Voice to Parliament be a lawyers’ picnic, and lead to lots of High Court challenges?" key="1">
                                                <p>How Parliament responds (or does not respond) to any representations made by the Voice would be non-justiciable – that is, it could not be subject to any court challenge. This is because the courts have always been reluctant to interfere with the internal workings of Parliament. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Will the Voice not help to close the gap or have a positive impact on the lives of First Nations peoples?" key="1">
                                                <p>The Voice will provide advice to the Parliament on proposed laws affecting First Nations peoples. Accordingly, Parliament will be better-informed about the impact of the proposed laws on First Nations peoples, and can amend where appropriate. A better-informed Parliament is likely to lead to better laws that will have a positive impact on First Nations lives. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Won’t the Voice give First Nations peoples special rights?" key="1">
                                                <p>The Constitutional Expert Group comprising nine experts (including former High Court judge Kenneth Hayne) and chaired by the Commonwealth Attorney-General has advised that a First Nations Voice will not give First Nations peoples special rights. All Australians have the right to make representations to Parliament, which is guaranteed by the constitutional Implied Freedom of Political Communication. The First Nations Voice is simply a permanent one. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Shouldn’t Australians be allowed to see all the proposed legislation establishing the Voice before voting in the referendum?" key="1">
                                                <p>Too much detail will lead to confusion, and many people will likely not want to read a lengthy document. There’s already a detailed report that sets out what a legislated Voice could look like: the Indigenous Voice Co-design Process Final Report. Demanding to see draft legislation ahead of the referendum suggests a lack of trust in Parliament, given that the proposed constitutional amendment gives Parliament the power “to make laws with respect to the composition, powers, functions and procedures” of the Voice. It’s sufficient to have a detailed set of principles on which the Voice will be based. The Uluru Statement from the Heart website also contains a set of design principles. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="But there’s no need to enshrine the Voice in the Constitution, is there?" key="1">
                                                <p>By enshrining the Voice in the Constitution, it will not be able to be abolished at the whim of Parliament/the government, in contrast to ATSIC (and just about every other Indigenous advisory body set up by the government). It will also not be afraid to give frank and fearless advice. Its composition, powers and procedures will, however, be able to be amended by Parliament to ensure its effectiveness. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Won’t the Voice divide the nation?" key="1">
                                                <p>The Voice to Parliament will unite the nation, because it will be a big step towards reconciliation. A successful referendum on the Voice to Parliament will mean the Australian people have emphatically said that we want Parliament to listen to First Nations people, thereby signalling that we have accepted the invitation in the Uluru Statement from the Heart to walk together for a better future. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Aren’t Indigenous Australians divided over the Voice, so it shouldn’t be supported?" key="1">
                                                <p>Although there are some high-profile Indigenous Australians who do not at this point in time support the Voice (such as Jacinta Nampijinpa Price, Warren Mundine and Lidia Thorpe), a significant proportion of First Nations people do support the Voice. An IPSOS poll conducted in late January 2023 found that 80% of First Nations people support the Voice. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Doesn’t it offend the notion of equality that underpins the Constitution and our democracy?" key="1">
                                                <p>Our Constitution does not protect equality, and actively allows for racially discriminatory laws by virtue of s 51 (xxvi) (the race power). Further, the race power has only ever been used to make laws for Aboriginal and Torres Strait Islander peoples, laws that are not required to be beneficial laws. The structure of our Parliament is also not equal – the Constitution requires the Senate to have the same number of senators from each state (12). This means that Tasmania, with a population of approximately 571,500, has the same number of senators as Victoria, which has a population of 6,613,700. Amending the Constitution to provide First Nations peoples with a Voice to Parliament does not offend notions of equality; rather, it is acknowledging the finding of the High Court in Mabo v Queensland (No. 2) that “Their dispossession underwrote the development of the nation”. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse>

                                        <Divider orientation="left"></Divider>
                                        <Collapse style={{ backgroundColor: 'white' }}>
                                            <Panel header="Doesn’t the history of referendums in Australia mean that it is likely to fail.?" key="1">
                                                <p>The most successful referendum in Australia’s history of referendums was in relation to Aboriginal people (1967). More than 90% of Australians voted ‘yes’ to amending two sections of the Constitution to ensure that Aboriginal and Torres Strait Islander peoples would be counted as part of the population, and that the Commonwealth would be able to make laws for them. This bodes well for a referendum on the Voice. Also, social media has changed the landscape; times are different; polling shows relatively consistent support; and approximately 90% of the first 2554 submissions to the Co-design Process were in favour of the Voice being constitutionally enshrined. And although bipartisan/multi-party support would be preferable (and has been crucial to the success of previous referendums), it’s arguably no longer a determinative factor due to the changing political and social media landscape. The result of the marriage equality postal survey, where Australians voted “Yes” despite a lack of bipartisan support, is indicative of this. (Answer provided by Katie O’Bryan, Lecturer, Faculty of Law, Monash University and Paula Gerber, Professor of Law, Monash University. “Voice to Parliament: Voice to Parliament: Debunking 10 myths and misconceptions”)</p>
                                            </Panel>
                                        </Collapse> */}



                                    </div>
                                </div>
                            </div>
                            <div style={{ 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'flex-end', 
                                        //alignItems: 'center',
                                        alignItems: 'flex-end', 
                                        width: '95%', 
                                        margin: '30px 0 30px 0',
                                        position: 'relative', }}>
                                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <QuestionCircleOutlined onClick={showModal} style={{ fontSize: '40px' }} />
                                <span 
                                    style={{ 
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, 50%)',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        color: 'white',
                                        whiteSpace: 'nowrap',
                                        bottom: '-25px',
                                    }}
                                    onClick={showModal}
                                >
                                    Any queries ask us here!!!
                                </span>
                            </div>
                            </div>
                        </div>
                    </div>
                    <Modal title="Ask Us" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ width: '30%' }}>
                                <Input onChange={(e) => { setname(e.target.value) }} placeholder="Name *" />
                            </div>
                            <div style={{ width: '30%' }}>
                                <Input onChange={(e) => { setemail(e.target.value) }} placeholder="Email *" />
                            </div>
                            <div style={{ width: '30%' }}>
                                <Input onChange={(e) => { setphone(e.target.value) }} placeholder="Phone" value={phone === null ? '' : phone} />
                            </div>
                        </div>

                        <div>
                            <TextArea
                                showCount
                                maxLength={100}
                                style={{
                                    height: 120,
                                    marginBottom: 24,
                                    marginTop: 20
                                }}
                                onChange={(e) => setquestion(e.target.value)}
                                placeholder="type Your Question... *"
                            />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <div >
                                <Button size='large' onClick={onSubmit} style={{ backgroundColor: '#44aa4a' }} type="primary">Submit</Button>
                            </div>
                            <div >
                                <Button size='large' onClick={handleCancel}>Cancel</Button>
                            </div>
                        </div>
                    </Modal>
                </Content>
            </Spin>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
                    color: 'white'
                }}
            >
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default FaqPage;
