import React, { useEffect, useState } from 'react';
import { Layout, Collapse, Button, Modal, Input, Spin } from 'antd';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const { Panel } = Collapse;
const { Content, Footer } = Layout;
const { TextArea } = Input;

function StoryTellingPage() {
    const navigate = useNavigate(); // Hook to navigate between pages
    const [storyData, setStoryData] = useState([]); // State to store story data
    const [places, setPlaces] = useState({}); // State to store city coordinates

    useEffect(() => {
        // Fetch city data and set places state
        const fetchPlaces = async () => {
            try {
                const res = await axios.get('https://api.greenslopesreconciliationactiongroup.com/api/City/Get'); // Fetch city data
                const cities = res.data;
                const placesObj = {};
                cities.forEach(city => {
                    const [latitude, longitude] = city.Coordinates.split(',').map(parseFloat);
                    placesObj[city.Name] = [latitude, longitude]; // Store city coordinates
                });
                setPlaces(placesObj); // Update places state
            } catch (err) {
                console.log(err); // Log error if any
            }
        };

        // Fetch story data for all cities
        const fetchStoryData = async () => {
            try {
                const res = await axios.get('https://api.greenslopesreconciliationactiongroup.com/api/Story/storydata'); // Fetch story data
                setStoryData(res.data); // Update storyData state
            } catch (err) {
                console.log(err); // Log error if any
            }
        };

        fetchPlaces();
        fetchStoryData();
    }, []);

    useEffect(() => {
        // Create the map and add tile layer
        const map = L.map('map').setView([-25.48, 134.47], 5); // Initialize map
        const ausBounds = [[-50, 105], [0, 165]];
        map.setMaxBounds(ausBounds); // Set map bounds
        map.setMaxZoom(7.5);
        map.setMinZoom(5);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors'
        }).addTo(map); // Add tile layer to the map

        // Create markers for each city and bind popups
        const createMarkers = (places, storyData) => {
            for (const place in places) {
                let markerIcon;
                // Different icon for specific cities
                if (['Sydney', 'Melbourne', 'Alice Springs', 'Darwin'].includes(place)) {
                    markerIcon = L.icon({
                        iconUrl: process.env.PUBLIC_URL + '/blue-marker.png',
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                        popupAnchor: [0, -32]
                    });
                } else {
                    markerIcon = L.icon({
                        iconUrl: process.env.PUBLIC_URL + '/red-marker.png',
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                        popupAnchor: [0, -32]
                    });
                }

                const marker = L.marker(places[place], { icon: markerIcon }).addTo(map);

                // Bind popup with dynamic content including city name and story options
                marker.bindPopup(getPopupContent(place, storyData));
            }
        };

        // Helper function to generate popup content
        const getPopupContent = (place, storyData) => {
            if (!Array.isArray(storyData)) {
                console.error("storyData is not an array:", storyData);
                return ""; // Return an empty string or handle the error appropriately
            }

            const cityStories = storyData.filter(story => story.CityName === place); // Filter stories for the city

            if (cityStories.length === 0) {
                return `
                    <div>
                        <h3>${place}</h3>
                        <p>No stories available.</p>
                    </div>`;
            } else {
                return `
                    <div>
                        <h3>${place}</h3>
                        <p>${cityStories[0]?.CityDescription || ''}</p>
                        <p className="city_id" style="display:none">${cityStories[0]?.CityId}</p>
                        <select onchange="handleSelectChange(event, '${place}')">
                            <option value="" >Select Story</option>
                            ${cityStories.map(story => `<option value="${story.Id}">${story.Name}</option>`).join('')}
                        </select>
                    </div>`;
            }
        };

        // Add event listener for select change
        window.handleSelectChange = (event, place) => {
            const storyId = event.target.value;
            if (storyId) {
                window.open(`/storydetail/${storyId}`, '_blank'); // Open story detail page
            }
        };

        createMarkers(places, storyData); // Create markers with updated data

        return () => {
            map.remove(); // Clean up the map on component unmount
            window.handleSelectChange = null; // Clean up the global function
        };
    }, [places, storyData]);

    return (
        <Layout className="layout">
            <Navbar page={'StoryTellingPage'} />
            <Content
                style={{
                    padding: '80px 50px',
                    backgroundColor: '#060d29',
                    color: 'white',
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr auto', 
                    minHeight: '100vh',
                }}
            >
                <div style={{textAlign: "center"}}>
                    <a onClick={()=>{navigate('/uploadStory')}} ><h2>Upload Your Story?</h2></a> {/* Link to upload story page */}
                </div>  

                <div style={{textAlign: "center", marginTop: "10px"}}>
                    <h1> List Of Other Stories</h1> {/* Header */}
                </div>     

                <div>
                    <div id="map" style={{ 
                        position: 'relative',
                        height: '800px',
                        width: '100%',
                        borderRadius: '8px',
                        marginTop: '20px'
                    }}></div> {/* Map container */}
                </div>

            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
                    color: 'white'
                }}
            >
                Greenslopes Reconcilation Action Group {/* Footer text */}
            </Footer>
        </Layout>
    );
}

export default StoryTellingPage;
