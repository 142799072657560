import React from 'react';
import { Breadcrumb, Layout, Menu, theme, Carousel, Button, Modal } from 'antd';
import Navbar from '../../components/Navbar';
import styles from "./Home.module.css";
import backgroundImage from '../../assets/reconciliation-feature-fade.jpg';
import portionImage from '../../assets/34256922-removebg-preview.png';
import { useState , useEffect } from 'react';
import { List } from 'antd/es/form/Form';
import facebookImage from '../../assets/Facebook_Logo_2023.png'
import { FacebookShareButton } from 'react-share';

const { Header, Content, Footer } = Layout;
const shareURL = "https://greenslopesreconciliationactiongroup.com"

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
function Home() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const [currentYear, setCurrentYear] = useState(null);

  const initialModalState = sessionStorage.getItem('modalShown') !== 'true';
  const [isModalOpen, setIsModalOpen] = useState(initialModalState);

  const [NewsItemData, setNewsItemData] = useState([]);


  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();

    getNewsItemList();

    setCurrentYear(year);
    if (!isModalOpen) {
      sessionStorage.setItem('modalShown', 'true');
  }
  }, [isModalOpen]);
  //const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);

  };
  const handleCancel = () => {
    setIsModalOpen(false);

  };
  const getNewsItemList = async () => {
    console.log(1111);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", ".AspNetCore.Identity.Application=CfDJ8KQ9_KBcFi9Cu3eDViVJ7FvgRtprux03WlK27tizKa63y4q2pcTtilFCDbEATh8124uU1uVtn-evKsfPXt0-KMNS8w-d02hCQS5X5J-8mqu9K0jVxvfarRJU78q_lvxTE3OtPwsp4GC-QB5XGfiqH6sE0VWXAUIC-kZFPDSKy2zcrX4Cb2IKpLi5q-vRbE5z_6DVQVd2KdGYDdxzrWeNJhRRj0zuwPj_5IypCqk4l9DuUDi_pItdNJEumaNi-rSvWXAmJ74bDaZITYBZky0xaMwzBMJAGoaDdd9zvpX2OrbZm1Y5a0I-ou1Wi_mBXNdxIaO76BYF0Fc13haOvmN9EB4kCk4I6jEEQ6sSUfqLkyE-5H2RHEsOdbaldv7kZGZofz3Uj9mMGTAv2yoYN_9Lk-hUxueTXXJuWCSIpGzZcncAqHIq3CGg2YVdm2AXOZr3HZ9jXdfbjHo4AXGms128pGQdjVkj0whJXRHYl-ubN3aI3zS03Njfuj3DnOI9skhR0lVqiitOopkjFyM9ZlEdgnSgWtcA7s_dZM8tmP8Rt1kj78upFfoVBhNC6KJcuQNxTtni3Y6EtDA590uE0odhvKWGC7FDmlXP6CZQD6CGQIQ0rXJnDw6Ku2jXANhp4ZaFt06pMSf5v-IYu-ZS-kB9jVuFIEZr-BnE5UioOCiYRKtyMTXLHOpxH93AXDv1w5FVrSbvwwXim_JP7Q2_aYAopOY60PcSb4gfJODFmWFV6vnB");

    //const response = await fetch("https://api.greenslopesreconciliationactiongroup.com/api/StoryTelling", {
    const response = await fetch("https://api.greenslopesreconciliationactiongroup.com/api/NewsItems", {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    });
    const result = await response.json();
    console.log('reponse', result)
    if (result?.Status == 'Success') {
        setNewsItemData(result?.Result)
        // setSelectedItem(result?.Result[0])
        // setLoading(false)
    }

}
  return (
    <Layout className="layout">
      <Navbar />
      <Content
        style={{
          // padding: '0 50px',
          backgroundColor:'rgba(0, 0, 0, 0.8)',
          // color:'white'
        }}
      >
        <div
          className="site-layout-content"
          style={{
            background: 'rgba(0, 0, 0, 0.8)',
            // color:'white'
            // height: '100vh'
          }}
        >
          <div >
          <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' ,backgroundSize: 'cover',backgroundBlendMode: 'overlay',backgroundColor: 'rgba(0, 0, 0, 0.8)' ,backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  opacity: '1.5 !important'}}>
                  <div style={{ position: 'absolute', top: '110px', right: '60px' }}>
                    <div style={{ color: 'white', position: 'relative', bottom: '0.4em', right: '0.3em' }}>Share</div>
                      <FacebookShareButton url={shareURL}>
                          <img src={facebookImage} alt="Facebook Logo" style={{width: "30px", height: "30px", transition: "opacity 0.2s ease-in-out"}}
                              onMouseEnter = {(facebookLogo) => facebookLogo.target.style.opacity = "0.5"} 
                              onMouseLeave = {(facebookLogo) => facebookLogo.target.style.opacity = "1"} />
                      </FacebookShareButton>
                      
                  </div>
              <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' , height: '100vh' }}>
                <h1 style={{ fontSize: '40px' ,margin: 'auto', color:'rgb(92, 119, 84)' }}>Welcome, Greenslopes Reconciliation Action Group</h1>



              </div>
            </div>

            <Carousel autoplay style={{ border: '1px solid #'}} afterChange={onChange}>

             {
              NewsItemData.map(item=>(
                  <div key={item.Id} >
                    <div className={styles.CarouselDiv} style={{  height: '100vh' , backgroundColor: '#rgba(0, 0, 0, 0.8)'}}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '60%',color:'white', textAlign: 'center' ,color:'rgb(92, 119, 84)'}}>
                        <h1>{item.Title}</h1>
                        <p>{item.Description} </p>
                      </div>
                      {
                        item.ImageUrl!=null?( <div style={{ display: 'flex', justifyContent: 'center', width: '30%',height:'50%',color:'white' }}>
                                                <img src={'https://api.greenslopesreconciliationactiongroup.com/'+item.ImageUrl} />
                                              </div>):('')
                      }
                     
                    </div>
                </div>
              ))
             }

              {/* <div >
                <div className={styles.CarouselDiv} style={{  height: '100vh' , backgroundColor: '#rgba(0, 0, 0, 0.8)'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '60%',color:'white', textAlign: 'center' ,color:'rgb(92, 119, 84)'}}>
                    <h1>News item</h1>
                    <p>The Greenslopes Reconciliation Action Group Reconciliation Walk will be held on 28 May 2023.  Join with us at one of our two starting points (Whites Hill Reserve or Mt Gravatt Lookout) as we walk on country and meet at the Holland Park Sports Club for a community event where we will connect and celebrate actions that will support greater reconciliation here in our local community.  Please register to attend to support planning for this event.  You can register now here: <a href='https://www.trybooking.com/CHQYL'> https://www.trybooking.com/CHQYL</a> </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '30%',color:'white' }}>
                    <img src={require('../../assets/item1.png')} />
                  </div>
                </div>
              </div>

              <div >
                <div className={styles.CarouselDiv} style={{  height: '100vh', backgroundColor: '#(0, 0, 0, 0.8)' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '60%',color:'white' , textAlign: 'center' ,color:'rgb(92, 119, 84)'}}>
                    <h1>News item</h1>
                    <p>Yes23 is a campaign led by Australians for Indigenous Constitutional Recognition Ltd. It brings together grassroots organisations all campaigning for constitutional recognition of Aboriginal and Torres Strait Islander people through a voice to parliament. To find out more visit <a href='https://yes23.com.au/'>https://yes23.com.au/</a>.</p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '30%',color:'white' }}>
                    <img src={require('../../assets/acv.png')} />
                  </div>
                </div>
              </div>
              <div >
                <div className={styles.CarouselDiv} style={{  height: '100vh', backgroundColor: '#(0, 0, 0, 0.8)'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '60%',color:'white' , textAlign: 'center' ,color:'rgb(92, 119, 84)'}}>
                    <h1>News item</h1>
                    <p>On 10 May 2023 the Queensland Government passed the Path to Treaty Act 2023, a landmark Act for this state. The Act provides for a:
•	First Nations Treaty Institute to support Aboriginal and Torres Strait Islander peoples to prepare for treaty negotiations
•	Truth-telling and Healing Inquiry to hear and record the historical and ongoing impacts of colonisation on Aboriginal and Torres Strait Islander Queenslanders.
Find out more at: <a href='https://www.qld.gov.au/firstnations/treaty/queensland-path-to-treaty/about'>https://www.qld.gov.au/firstnations/treaty/queensland-path-to-treaty/about</a>
</p>
                  </div>
                </div>
              </div> */}
            </Carousel>


            
            <div style={{ backgroundColor: '#rgb(6, 13, 41)', width: '100vw', height: '100vh' }}>
            <div className={styles.ResponsiveDiv}>
            <div className={styles.CarouselDiv} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '50%', display: 'flex', flexDirection: 'column', color: 'white', padding: '20px' }}>
        <p style={{ textAlign: 'center', color: 'rgb(92, 119, 84)', lineHeight: 'normal', fontSize: '17px' }}>
        We are a group of individuals in the Greenslopes electorate seeking to contribute to active reconciliation in our community. Established in July, 2022, we meet regularly to discuss and explore the story of First Nations people of this community, past, present and ongoing. The group will explore and share the people, places, events, language and achievements of First Nations people in this community, while building a broader understanding of First Nations people and their culture. The group will seek to contribute to the process of reconciliation through building understanding, taking local action and supporting local events and activities.
        In 2023 Australians face the opportunity to take a step toward a better future with the proposal to establish a First Nations Voice to Parliament in the Australian Constitution. As a group we firmly support this. To explore these important issues please see our pages on The Uluru Statement from the Heart, Voice to Parliament and The Referendum.
        The Greenslopes Reconciliation Action Group acknowledges and pays respect to past, present and future Traditional Custodians and Elders and the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples.

        Aboriginal and Torres Strait Islander peoples should be aware that this website may contain images or names of people who have passed away.
      </p>
    </div>
    <div className={styles.ResponsiveDiv}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={require('../../assets/home.png')} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
    </div>
    </div>
    
  </div>
            </div>
            </div>


            
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}>
                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Greenslopes Reconciliation Action Group</h1>
                <p style={{ marginTop: '30px', lineHeight: 2 }}>We are a group of individuals in the Greenslopes electorate seeking to contribute to active reconciliation in our community. Established in July, 2022, we meet regularly to discuss and explore the story of First Nations people of this community, past, present and ongoing. The group will explore and share the people, places, events, language and achievements of First Nations people in this community, while building a broader understanding of First Nations people and their culture. The group will seek to contribute to the process of reconciliation through building understanding, taking local action and supporting local events and activities. In 2023 Australians face the opportunity to take a step toward a better future with the proposal to establish a First Nations Voice to Parliament in the Australian Constitution. As a group we firmly support this. To explore these important issues please see our pages on The Uluru Statement from the Heart, Voice to Parliament and The Referendum.</p>

                <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                  <img src={require('../../assets/home.png')} style={{width:'100%'}}/>
                </div>
                <p style={{ marginTop: '30px', lineHeight: 2 }}>The Greenslopes Reconciliation Action Group acknowledges and pays respect to past, present and future Traditional Custodians and Elders and the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples.</p>
                <p style={{ marginTop: '30px', lineHeight: 2 }}>Aboriginal and Torres Strait Islander peoples should be aware that this website may contain images or names of people who have passed away.</p>



              </div>
            </div> */}
          </div>
        </div>
        <Modal   width={'80%'} centered footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <div >
            <h1 style={{ textAlign: 'center',color:'rgb(92, 119, 84)' }}>Acknowledgment and Content Advice</h1>
            <p style={{ textAlign: 'center' ,color:'rgb(92, 119, 84)'}}>Greenslopes reconciliation action group acknowledges and pays respect to Queensland’s past, present and future Traditional Custodians and Elders and the continuation of cultural, spiritual and educational practices of Aboriginal and Torres Strait Islander peoples. Aboriginal and Torres Strait Islander peoples should be aware that this website contains images or names of people who have died.</p>
          </div>
        </Modal>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
          backgroundColor: '#5c7754',
          color:'white'
        }}
      >
        Greenslopes Reconcilation Action Group
      </Footer>
    </Layout>
  );
}

export default Home;
