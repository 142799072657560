import React from 'react';
import { Layout, theme, Carousel } from 'antd';
import Navbar from '../../components/Navbar';
import styles from "./Information.module.css";

const { Content, Footer } = Layout;



function Statement() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout className="layout">
            <Navbar />
            <Content
                style={{
                    padding: '0 50px',
                    // backgroundColor:'#fff'
                    backgroundColor:'#060d29',
                    color: 'white',
                        display: 'grid',
                        gridTemplateRows: 'auto 1fr auto', 
                        minHeight: '100vh',
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        // background: colorBgContainer,
                        backgroundColor:'#060d29',
                        // height: '100vh'
                    }}
                >
                    <div style={{ marginTop: '20px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '80%', marginTop: '40px' }}>
                                {/* <h1 style={{ fontSize: '30px', textAlign: 'center' }}>Uluru Statement from the Heart</h1> */}
                                <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic' }}>What is Statement from the Heart?</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>In the words of the Uluru Dialogue organisation, “The Uluru Statement from the Heart is an invitation to the Australian people from First Nations Australians. It asks Australians to walk together to build a better future by establishing a First Nations Voice to Parliament enshrined in the Constitution, and the establishment of a Makarrata Commission for the purpose of treaty making and truth-telling.”</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}>
                                <h1 style={{ fontSize: '30px', textAlign: 'center' }}>ULURU STATEMENT FROM THE HEART</h1>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>We, gathered at the 2017 National Constitutional Convention, coming from all points of the southern sky, make this statement from the heart: </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Our Aboriginal and Torres Strait Islander tribes were the first sovereign Nations of the Australian continent and its adjacent islands, and possessed it under our own laws and customs. This our ancestors did, according to the reckoning of our culture, from the Creation, according to the common law from ‘time immemorial’, and according to science more than 60,000 years ago. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>This sovereignty is a spiritual notion: the ancestral tie between the land, or ‘mother nature’, and the Aboriginal and Torres Strait Islander peoples who were born therefrom, remain attached thereto, and must one day return thither to be united with our ancestors. This link is the basis of the ownership of the soil, or better, of sovereignty. It has never been ceded or extinguished, and co-exists with the sovereignty of the Crown. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>How could it be otherwise? That peoples possessed a land for sixty millennia and this sacred link disappears from world history in merely the last two hundred years? </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>With substantive constitutional change and structural reform, we believe this ancient sovereignty can shine through as a fuller expression of Australia’s nationhood. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Proportionally, we are the most incarcerated people on the planet. We are not an innately criminal people. Our children are aliened from their families at unprecedented rates. This cannot be because we have no love for them. And our youth languish in detention in obscene numbers. They should be our hope for the future. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>These dimensions of our crisis tell plainly the structural nature of our problem. This is the torment of our powerlessness. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>We seek constitutional reforms to empower our people and take a rightful place in our own country. When we have power over our destiny our children will flourish. They will walk in two worlds and their culture will be a gift to their country. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>We call for the establishment of a First Nations Voice enshrined in the Constitution. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>Makarrata is the culmination of our agenda: the coming together after a struggle. It captures our aspirations for a fair and truthful relationship with the people of Australia and a better future for our children based on justice and self-determination. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>We seek a Makarrata Commission to supervise a process of agreement-making between governments and First Nations and truth-telling about our history. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>In 1967 we were counted, in 2017 we seek to be heard. We leave base camp and start our trek across this vast country. We invite you to walk with us in a movement of the Australian people for a better future. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>In 2015 then Prime Minister Malcolm Turnbull and the Leader of the Opposition Bill Shorten appointed a Referendum Council. Its purpose was to advise on progress toward a referendum to recognise Aboriginal and Torres Strait Islander peoples in the Australian Constitution. The Council held a series or regional dialogues across the country, culminating in a National Constitutional Convention held at Uluru in 2017. The Statement, the largest consensus of First Nations peoples on a proposal for substantive recognition in Australian history, was the outcome of this convention.</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>For more information of the history and process that gave rise to the Statement please take a look at the website of the Uluru Dialogue. </p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>The Statement calls for “establishment of a First Nations Voice enshrined in the Constitution” as a first step in our journey together. To change the Constitution requires a referendum. To find out more please see our pages on The Voice to Parliament and The Referendum.</p>
                                <p style={{ marginTop: '30px', lineHeight: 2 }}>If you have more questions, our Question and Answers page may be a good place to check out.</p>


                            </div>
                        </div>

                        {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div className={styles.ColumnDiv} >
                                <div>
                                    <p style={{ marginTop: '30px', lineHeight: 2, fontStyle: 'italic', fontSize: '20px' }}>Access the links below for ore information.</p>
                                    <p style={{ marginTop: '30px', lineHeight: 2 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                    <div style={{ padding: '10px 50px 10px 50px' }}>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                        <p>• www.example.com.au</p>
                                    </div>
                                </div>
                                <div>
                                    <div style={{border:'1px solid'}}>
                                        <img src={require('../../assets/placeholderImg.png')} />
                                    </div>
                                </div>

                            </div>

                        </div> */}
                    </div>
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#5c7754',
          color:'white'
                }}
            >
                Greenslopes Reconcilation Action Group
            </Footer>
        </Layout>
    );
}

export default Statement;
